import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

const gif1Src = require('../../../images/arisak-black-udon/vol6_01.gif')
const gif2Src = require('../../../images/arisak-black-udon/vol6_02.gif')
const gif3Src = require('../../../images/arisak-black-udon/vol6_03.gif')

const videoSrc = require('../../../videos/ARISAK-UDON.mp4')


export const ArisakBlackUdon = () => {
  const [width, setWidth] = useState(0)
  const [showPhone, setShowPhone] = useState(false)
  const [hidePhone, setHidePhone] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleClickPlayButton = () => {
    if (!showPhone && !hidePhone) {
      setShowPhone(true)
    } else {
      setShowPhone(!showPhone)
      setHidePhone(!hidePhone)
    }
  }

  return (
    <>
      <OverflowHidden>
        <Container>
          <div className="section1">
            <Image path='arisak-black-udon/udon_main.jpg' />

            <div className="content">
              <div className="name">
                ARISAK BLACK UDON
              </div>
              <div className="description">
                ”毎日ダークファンタジー”を掲げる新進気鋭のフォトアーティストARISAK。彼女が愛してやまないソウルフード”UDON”を、竹炭を練り込んだ漆黒のBLACK UDONとして博多製麺処にスペシャルオーダー。かけうどんつゆは岐阜の山川醸造によるたまり醤油を贅沢に使用したこだわりのオリジナルうどんつゆ。つけつゆでも、ぶっかけつゆとしてもお楽しみいただけます。ARISAK撮り下ろしの写真をフルプリントした”メタルマスク”もセットとなったハロウィン仕様のスペシャルアイテム。卓上のダークファンタジーをぜひご自宅で体感ください。
              </div>
            </div>
          </div>

          <div className="section2">
            <img src={gif1Src} alt={gif1Src} />
          </div>

          <div className="section3">
            <div className="image-wrapper">
              <Image path='arisak-black-udon/Artist1.jpg' />
            </div>
            <div className="content">
              <div>
                <div className="title">Photo Artist</div>
                <div className="name">ARISAK</div>
                <div className="description">
                  １０年間のフィギュアスケーターとしての活動からインスパイア され、写真を撮り始める。近年はアートディレクションも同時に手がけ、独自のDarkFantasyスタイルで雑誌や広告など活動の幅を広げている。VOGUE JAPANにて日本の若手アーティスト10人に選ばれ、イタリアを代表する車ブランドFIATと契約を結び、セルフディレクションの元、1年間にわたり自撮り広告を展開。NIKE×sacaiのキャンペーンに元フィギュアスケーターとして登場するなど今話題のアーティスト。
                </div>
                <div className="instagram">
                  <span>Instagram：</span> <a href="https://instagram.com/arisak_official" target="_blank">@arisak_official</a>
                </div>
              </div>
            </div>
          </div>

          <SpOnly>
            <div style={{ padding: '0 40px' }}>
              <Image path='arisak-black-udon/01.jpg' />
              <br />
              <img src={gif2Src} alt={gif2Src} />
            </div>
          </SpOnly>

          <div className="section4">
            <PcOnly>
              <div className="images">
                <Image path='arisak-black-udon/shop1.jpg' />
                <Image path='arisak-black-udon/01.jpg' />
              </div>
            </PcOnly>
            <div className="content">
              <SpOnly>
                <Image path='arisak-black-udon/shop1.jpg' />
              </SpOnly>
              <div className="content-right">
                <div className="title">製麺処</div>
                <div className="name">博多製麺処</div>
                <div className="description">
                  うどん発祥の地、福岡のカリスマ製麺メーカー。麺作りを通じて日本の”美味しい”と”こだわり”を伝えている。
                </div>
                <div className="instagram">
                  <span>HP：</span> <a href="https://www.hakata-seimensho.com" target="_blank">www.hakata-seimensho.com</a><br />
                </div>
              </div>
              <PcOnly>
                <div className="gif2">
                  <img src={gif2Src} alt={gif2Src} />
                </div>
              </PcOnly>
            </div>
          </div>

          <div className="section5">
            <div className="left">
              <div>
                <Image path='arisak-black-udon/02.jpg' />
              </div>
              <img src={gif3Src} alt={gif3Src} />
            </div>
            <div className="content">
              <Image path='arisak-black-udon/shop2.jpg' />
              <div>
                <div className="title">たまり醤油醸造所</div>
                <div className="name">山川醸造</div>
                <div className="description">
                  昭和18年創業。長良川の伏流水と杉の木桶を使い、東海地方の伝統的な調味料、豆味噌とたまり醤油を醸造する醸造蔵。 国内製造量１％ほどの木桶仕込みの製法で、さらに希少なたまり醤油・豆味噌を２年以上の時間をかけて作り続けている。「醤油を主役に」をテーマに手間暇かけて作る、希少な味わいをお楽しみください。
                </div>
                <div className="instagram">
                  <span>HP：</span> <a href="https://tamariya.com" target="_blank">tamariya.com</a><br />
                  <span>Instagram：</span> <a href="https://instagram.com/tamariya_gifu" target="_blank">@tamariya_gifu</a>
                </div>
              </div>
            </div>
          </div>

          <div className="video">
            <video controls>
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        </Container>
      </OverflowHidden>
    </>
  )
}

const OverflowHidden = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  .instagram {
    a {
      color: #000;
    }
  }

  @media screen and ( max-width: 768px ) {
    padding: 0 20px;

    .instagram {
      font-size: 0.8rem;
      margin: 5px 0 20px;

      span {
        font-weight: bold;
      }
    }
  }

  @media screen and ( min-width: 769px ) {
    margin: 80px auto 0;
    width: 1000px;

    .instagram {
      font-size: 1.2rem;
      margin: 10px 0 100px;

      span {
        font-weight: bold;
      }
    }
  }

  .title, .name {
    font-family: 'EB Garamond', serif;
    font-weight: bold;
  }
  
  .title {
    @media screen and ( max-width: 768px ) {
      font-size: 1rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 2rem;
      margin-bottom: -14px;
    }
  }

  .name {
    @media screen and ( max-width: 768px ) {
      font-size: 3rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 4rem;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  .description {
    font-family: 'TsukuBRdGothic-Regular',sans-serif;

    @media screen and ( max-width: 768px ) {
      font-size: 1.2rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 1.5rem;
      line-height: 2.8rem;
    }
  }

  .section1 {
    @media screen and ( max-width: 768px ) {
      margin-top: 48px;
    }
    @media screen and ( min-width: 769px ) {
      .gatsby-image-wrapper {
        margin: 0 auto;
        width: 800px;
      }
    }

    .content {
      @media screen and ( max-width: 768px ) {
        .description {
          margin-top: 12px;
        }
      }
      @media screen and ( min-width: 769px ) {
        margin: 0 auto;
        width: 620px;
      }
    }
  }

  .section2 {
    @media screen and ( max-width: 768px ) {
      img {
        margin: 48px auto 24px;
        width: 80%;
      }

      .content {
        .description {
          margin: 6px 0 48px;
        }
      }

      .artist, .shop {
        display: flex;

        .gatsby-image-wrapper {
          width: 40%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        .artist-content, .shop-content {
          padding-left: 10px;
          width: 60%;
        }
      }

      .gatsby-image-wrapper {
        margin-bottom: 20px;
      }
    }
    @media screen and ( min-width: 769px ) {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .content {
        width: 55%;

        .name {
          margin-bottom: 0;
          text-align: left; 
        }
      }

      img {
        height: 205px;
        width: 308px;
        margin: 0 auto;
      }
    }
  }

  .section3 {
    @media screen and ( max-width: 768px ) {
      display: flex;
      justify-content: space-between;

      .image-wrapper {
        margin-right: 10px;
        .gatsby-image-wrapper {
          width: 100px;
        }
      }
      
      .content {
        .title {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }
        
        .name {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
          display: flex;
          align-items: center;
          height: 30px;
          span {
            display: inline-block;

            &.store-name {
              margin-left: 4px;
              font-size: 20px;
            }
          }
        }
      }
    }
    @media screen and ( min-width: 769px ) {
      margin-top: 100px;
      display: flex;
      justify-content: center;

      > div {
        margin: 0 16px;
      }

      .gatsby-image-wrapper {
        width: 350px;
  
        > img {
          height: auto !important;
          object-fit: fill !important;
        }
      }

      img {
        height: 205px;
        width: 308px;
      }
  
      .content {
        width: 40%;

        .title {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }

        .name {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
          margin-top: 4px;
          margin-bottom: 0;
          text-align: left; 
        }
  
        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 400px;
        }
      }
    }
  }

  .section4 {
    @media screen and ( max-width: 768px ) {      
      .gatsby-image-wrapper {
        margin: 24px auto 24px;
        width: 80%;
      }

      img {
        margin: 0 auto 0;
        width: 80%;
      }

      .content {
        display: flex;
        margin-top: 36px;

        .gatsby-image-wrapper {
          margin-top: 10px;
          width: 100px;
        }

        .content-right {
          padding-left: 8px;
        }
        
        .title {
          font-size: 16px;
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }

        .name {
          margin-top: -6px;
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }
      }
    }
    @media screen and ( min-width: 769px ) {
      .images {
        display: flex;
        justify-content: space-between;

        .gatsby-image-wrapper {
          width: 40%;
          
          &:first-child {
            margin-top: 60px;
            img {
              height: auto !important;
            }
          }
        }
      }

      .content {
        display: flex;
        margin-top: -24px;

        .title {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }

        .name {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
          text-align: left;
          margin-top: 8px;
          margin-bottom: 0;
        }

        .description {
          width: 480px;
        }

        .content-right {
          width: 700px;
        }

        .gif2 {
          margin-top: 48px;
          margin-left: -48px;

          img {
            width: 300px;
          }
        }
      }
    }
  }

  .section5 {
    @media screen and ( max-width: 768px ) {
      img {
        margin: 0 auto;
        width: 80%;
      }

      .gatsby-image-wrapper {
        margin: 40px auto 24px;
        width: 100px;
      }

      .content {
        display: flex;
        margin-top: 36px;

        .title {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }
        
        .name {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
          display: flex;
          align-items: center;
          height: 30px;
          span {
            display: inline-block;

            &.store-name {
              margin-left: 4px;
              font-size: 20px;
            }
          }
        }

        .gatsby-image-wrapper {
          width: 30%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        > div {
          padding-left: 20px;
          width: 70%;
        }
      }
    }
    @media screen and ( min-width: 769px ) {
      margin-top: 100px;
      display: flex;
      justify-content: center;

      .left {
        > div {
          margin-top: -80px;
          margin-left: 50px;
        }
      }

      > div {
        margin: 0 16px;
      }

      .gatsby-image-wrapper {
        width: 200px;
  
        > img {
          height: auto !important;
          object-fit: fill !important;
        }
      }

      img {
        height: 205px;
        width: 308px;
      }
  
      .content {
        width: 45%;
        margin-left: 100px;

        .title {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
        }

        .name {
          font-family: 'Tsukushi B Round Gothic'; font-weight: normal;
          font-weight: bold;
          margin-top: 4px;
          margin-bottom: 0;
          text-align: left; 
        }
  
        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 400px;
        }
      }
    }
  }

  .video {
    @media screen and ( max-width: 768px ) {
      margin: 40px 0;

      video {
        width: 100%;
      }
    }
    @media screen and ( min-width: 769px ) {
      width: 800px;
      margin: 100px auto 0;

      video {
        width: 800px;
      }
    }
  }
`


const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 40px 20px 0;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
`

const Image4Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    -webkit-flex-basis: 100px;
    flex-basis: 100px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    -webkit-flex-basis: 300px;
    flex-basis: 300px;
  `}

  flex: none;
  position: relative;
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 0 0 50px;
  `}

  position: relative;
`

const ProductName = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ProductDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const BoxGifWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 50px 0 0 160px;
    width: 360px;
  `}
`

const ArtistAndShopContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 50px 0;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 50px;
  `}
`

const ArtistAndShopDetails = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  z-index: 99;
`

const ArtistAndShopDetailContainer = styled.div`
${customMedia.lessThan("medium")`
  margin: 0 20px;
`}
${customMedia.greaterThan("medium")`
`}

z-index: 99;
`

const ArtistAndShopTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
    margin-top: 10px;
  `}
`

const ArtistAndShopNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Image5Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const Image6Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 120px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 280px;
    margin-right: 48px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Image7Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const ArtistAndShopDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    margin-right: 100px;
    width: 468px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const Image8Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 250px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin-top: 60px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Instagram = styled.div<{ align?: string }>`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    margin-top: 10px;

    span {
      font-size: 6px;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    ${props.align && `text-align: ${props.align}`};
    
    span {
      font-size: 1.2rem;
    }
  `}

  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  text-align: left;

  span {
    font-weight: bold;
  }
`