import React from 'react'
// import { EgglifeT } from './egglife-t'
// import { Studs } from './studs'
// import { BrooklynMachineWorksGangstaUcchieEdition } from './brooklyn-machine-works-gangsta-ucchie-edition'
// import { HinomaruSocks } from './hinomaru-socks'
// import { PaloSanto } from './palo-santo'
import { ArisakBlackUdon } from './arisak-black-udon'

export const ProductList = () => {
  return <ArisakBlackUdon />
}
