import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Home } from '../components/Home'
import { ProductList } from '../components/ProductList'
import { ProductDetail } from '../components/ProductDetail'
import { ProductForm } from '../components/ProductForm/'
import { Archive } from '../components/Archive'

export const query = graphql`
  query($handle: String = "arisak-black-udon") {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        sku
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const product = data.shopifyProduct

  return (
    <Layout>
      <SEO
        title='あらゆる境界を越えてカルチャーをつなぐ | ICMarket'
        description='人とカルチャーをつなぐブランドマーケット『INSPIRATION CULT MARKET』です。ファッション、ライフスタイル、アート、フードといったストリートのVIBESとMINDを大切に、これまでにない新しさ、楽しさのあふれるコラボを提案していきます。'
        keywords={['ストリート', 'ファッション', 'カルチャー', 'コラボ', 'ブランド', 'ICMarket']}
      />
      <H1></H1>
      <Home />
      <ProductList />
      <ProductDetail />
      <ProductForm product={product} />
      <Archive />
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
